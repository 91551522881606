import React, { useEffect, useState } from "react";
import axios from "axios";

const PaymentIframe = () => {
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    window.parent.postMessage(
      JSON.stringify({
        type: 'custom_provider_ready',
        loaded: true,
      }),
      '*'
    )

    const handleMessages = async (event) => {
      console.log("Received payment data:", event.data);
      console.log("Received payment data parse:", JSON.parse(event.data));

      const payload = JSON.parse(event.data);
      if (payload.invoiceId && payload.locationId) {
        setLoading(true)
        const invoiceId = payload.invoiceId;
        const locationId = payload.locationId;
        const url = `${process.env.REACT_APP_URL}/oAuth/getInvoiceData?invoiceId=${invoiceId}&locationId=${locationId}`;
        axios.get(url)
          .then((response) => {
            console.log('Data successfully get:', response.data);
            // Redirect using the link from the API response
            const redirectionLink = response.data.data.link;

            if (redirectionLink) {

              // Start polling for refund status
              let pollingInterval = setInterval(async () => {
                const success = await hitDelayedApi(invoiceId, locationId);
                if (success) clearInterval(pollingInterval);
              }, 5000);



              openModal(redirectionLink);
              // window.open(redirectionLink, "_blank", "noopener,noreferrer");

            } else {
              console.error('No redirection link provided in the response');
            }
          })
          .catch((error) => {
            console.error('Error getting data:', error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (payload.transactionId && payload.locationId) {
        setLoading(true)
        await postTransactionData(payload);
      } else {
        console.error('Invalid data received:', event.data);
      }

    };
    window.addEventListener('message', handleMessages);

  }, []);



  const postTransactionData = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_URL}/oAuth/paymentLink`, data);
      console.log("PaymentLink Transaction data successfully posted:", response.data);

      if (response.data.status) {
        const redirectionLink = response.data.data.link;
        if (redirectionLink) {
          console.log(redirectionLink, "redirectionLink  L2C")
          // window.location.href = redirectionLink;

          // Start polling for refund status
          let pollingInterval = setInterval(async () => {
            const success = await paymentLinkStatus(data.transactionId, data.locationId);
            console.log(success, "success")
            if (success) clearInterval(pollingInterval);
          }, 5000);
          // window.open(redirectionLink, "_blank", "noopener,noreferrer");




          openModal(redirectionLink);






        } else {
          console.error('No redirection link provided in the response');
        }
      } else {
        console.error("Payment link creation failed:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Error response from server:", error.response.data);
      } else {
        console.error("Error posting transaction data:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const paymentLinkStatus = async (transactionId, locationId) => {
    console.log("Checking payments link status...");
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/webhook/paymentLinkStatus`, {
        transactionId: transactionId,
        locationId: locationId
      });

      if (response.data.success && response.data.chargeId) {
        console.log("Payment Success with chargeId!..", response.data.chargeId);

        window.parent.postMessage(
          JSON.stringify({
            type: "custom_element_success_response",
            chargeId: `${response.data.chargeId}`,
          }),
          "*"
        );
        return true; // Stop polling
      } else {
        console.log("payment not ready yet, checking again...");
        return false; // Continue polling
      }
    } catch (error) {
      console.error("Error in payment status:", error);
      return false;
    }
  }


  const hitDelayedApi = async (invoiceId, locationId) => {
    try {
      console.log("Checking payments status...");
      const response = await axios.post(`${process.env.REACT_APP_URL}/webhook/refundNotify`, {
        invoiceId: invoiceId,
        locationId: locationId
      });

      if (response.data.success && response.data.gatewayTransactionId) {
        console.log("Payment Success with gatewayTransactionId!..", response.data.gatewayTransactionId);

        window.parent.postMessage(
          JSON.stringify({
            type: "custom_element_success_response",
            chargeId: `${response.data.gatewayTransactionId}`,
          }),
          "*"
        );
        return true; // Stop polling
      } else {
        console.log("payment not ready yet, checking again...");
        return false; // Continue polling
      }
    } catch (error) {
      console.error("Error in payment status:", error);
      return false;
    }
  };


  const openModal = (url) => {
    setIframeSrc(url);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setIframeSrc("");
  };



  return (
    <>
      {modalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "62%",
              height: "78%",
              background: "white",
              // padding: "20px",
            }}
          > <p style={{ color: "red", padding: "10px", fontWeight: "400", fontSize: "13px", margin: "0", textAlign: "center",background: "#ff00001f" }}>
              Please do not refresh or close this page while your payment is being processed. You will be automatically redirected once the payment is successful.
            </p>
            <iframe src={iframeSrc} style={{ width: "100%", height: "100%", border: "none" }}></iframe>
            {/* <button
              onClick={closeModal}
              style={{ position: "absolute", top: "10px", right: "10px" }}
            >
              Close
            </button> */}
          </div>
        </div>
      )}


      {/* <div>
        <h2>{loading ?? "Loading..."}</h2>
        {!loading && (
          <p style={{ color: "red", fontWeight: "bold" }}>
            Please do not refresh or close this page while your payment is being processed..{" "}
            <span
              style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                window.location.reload();
              }}
            >
              Click here
            </span>
          </p>
        )}
      </div> */}
    </>


  );
};

export default PaymentIframe;
