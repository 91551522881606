import { useEffect } from "react";


function RefundUrl() {
    useEffect(() => {
        

        console.log("RefundUrl file activated")

        window.parent.postMessage(
            JSON.stringify({
              type: "custom_element_success_response",
              chargeId: "EC58C7A5_C3FB6F98",
            }),
            "*"
          );         

    }, []);

    console.log("RefundUrl file activated 2nd")
    return (
        <div>
            <h2>GHL Payment</h2>
           
        </div>
    );
}

export default RefundUrl;
